import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import SimpleReactLightbox from 'simple-react-lightbox'
import { SRLWrapper } from 'simple-react-lightbox'
import Img from 'gatsby-image'


export const GalleryPageTemplate = ({ title, content, contentComponent, images, publicURLs }) => {
  const PageContent = contentComponent || Content
  const GalleryOptions = {
    buttons: {
      showDownloadButton: false,
      showThumbnailsButton: false,
    },
    progressBar: {
      showProgressBar: false
    },
    settings: {
      disablePanzoom: true,
      autoplaySpeed: 5000,
    }
  }
  return (
    <section className="section section--gradient content">
      <div className="container">
        <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
          {title}
        </h2>
        <PageContent className="content" content={content} />
        
        <SimpleReactLightbox>
          <SRLWrapper options={GalleryOptions}>
            <div className="columns is-multiline">
              {Object.keys(images).map(i => (
                <div className="column is-3">
                  <Img fluid={images[i].node.childImageSharp.fluid} style={{
                    height: `300px`
                  }} />
                </div>
              ))}
            </div>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
    </section>
  )
}

GalleryPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const GalleryPage = ({ data }) => {
  const { markdownRemark: post } = data
  const { galleriesQuery: gallery } = data
  let publicURLs = buildArray(gallery.edges);
  function buildArray(array) {
    var newArray = new Array();
    for (var i = 0; i < array.length; i++) {
      newArray.push(array[i].node.publicURL);
    }
    console.log(newArray);
    return newArray;
  }  

  return (
    <Layout>
      <GalleryPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        images={gallery.edges}
        publicURLs={publicURLs}
      />
    </Layout>
  )
}

GalleryPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default GalleryPage

export const GalleryPageQuery = graphql`
  query GalleryPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
    galleriesQuery: allFile(filter: {relativePath: {glob: "gallery-imgs/*"}}) {
    edges {
      node {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
        publicURL
      }
    }
  }
  }
`